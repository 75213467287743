import {
  chakra,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text,
  useDisclosure,
  VStack,
  useOutsideClick,
} from "@chakra-ui/react";
import { ChevronDown, XCircle } from "lucide-react";
import { useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { TicketAction } from "src/types";

const ChevronDownIcon = chakra(ChevronDown);
const XCircleIcon = chakra(XCircle);

export default function TicketFilterAction({
  action,
  setAction,
}: {
  action?: TicketAction;
  setAction: (action: TicketAction | undefined) => void;
}) {
  const ref = useRef(null);
  const { isOpen, onToggle, onClose } = useDisclosure();
  useOutsideClick({
    ref,
    handler: onClose,
  });
  const [_, setSearchParams] = useSearchParams();
  return (
    <>
      <Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={onClose}
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <HStack>
            <HStack
              borderWidth={0.5}
              borderColor="gray.500"
              _hover={{ bg: "gray.600" }}
              px={4}
              py={2}
              cursor="pointer"
              bg={action ? "gray.600" : "transparent"}
              onClick={onToggle}
            >
              <Text>{action ?? "Action"}</Text>
              <ChevronDownIcon size={24} />
            </HStack>
            <XCircleIcon
              size={20}
              color={action ? "blue.500" : "transparent"}
              cursor="pointer"
              onClick={() => {
                action && setAction(undefined);
                setSearchParams({ page: "1" }, { replace: true });
              }}
            />
          </HStack>
        </PopoverTrigger>
        <PopoverContent w={160} p={0} ref={ref}>
          <PopoverBody p={0}>
            <VStack alignItems="flex-start" w="100%" gap={0}>
              {Object.values(TicketAction)
                .filter((a) => a !== TicketAction.RedeemIcpChainKeyAssets)
                .map((_action) => {
                  return (
                    <HStack
                      key={_action}
                      cursor="pointer"
                      w="100%"
                      pl={4}
                      py={2}
                      bg={action === _action ? "gray.600" : "transparent"}
                      _hover={{ bg: "gray.600" }}
                      onClick={() => {
                        setAction(_action as TicketAction);
                        setSearchParams({ page: "1" }, { replace: true });
                        onClose();
                      }}
                    >
                      <Text>{_action}</Text>
                    </HStack>
                  );
                })}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
}
