import {
  Avatar,
  Badge,
  HStack,
  Text,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  TableCaption,
  Box,
  Link,
  Divider,
  AvatarGroup,
} from "@chakra-ui/react";
import { ChainID } from "@omnity/widget/src/types";
import { useMetadata } from "src/contexts/metadata";
// import { gql } from "graphql-request";
// import { fetchGraph } from "../utils/graphFetch";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { beautifyTokenId } from "src/utils/format";
import { MD_WIDTH } from "src/utils/constants";
import { Helmet } from "react-helmet";
import { formatUnits, readableNumber } from "@omnity/widget/src/utils/format";
import useVolume from "src/hooks/useVolume";
import IChainLogo from "src/components/IChainLogo";
import { getAssetProtocol } from "src/utils/chains";
import { RunesOnL2Pie } from "src/components/TokensOnL2Pie";
import AssetBadge from "src/components/AssetBadge";
import TicketFilterChainId from "src/components/TicketFilterChainId";
import _ from "lodash";

// async function fetchTicketCountByToken(token_id: string) {
//   try {
//     const doc = gql`
//       {
//         ticket_aggregate(
//           where: { token: { _eq: "${token_id}" } }
//         ) {
//           aggregate {
//             count
//           }
//         }
//       }
//     `;
//     const data = await fetchGraph(doc, {});
//     return data.ticket_aggregate.aggregate.count;
//   } catch (error) {
//     return 0;
//   }
// }

enum TokenProtocol {
  RUNES = "RUNES",
  ICRC = "ICRC",
  // BRC20 = "BRC-20",
  SPL = "Solana",
  DOGE = "DOGE",
}

export default function Tokens() {
  const { tokens, formatTokenAmount, chains } = useMetadata();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const _asset = searchParams.get("asset");
  const _chainId = searchParams.get("chainId");
  const [tp, setTp] = useState(_asset ?? TokenProtocol.RUNES);
  const [chainId, setChainId] = useState<ChainID | undefined>(
    (_chainId as ChainID) ?? undefined,
  );

  useEffect(() => {
    const params: Record<string, string | string[]> = {};
    if (chainId) {
      params.chainId = chainId;
    }
    if (tp) {
      params.asset = tp;
    }
    setSearchParams(params, { replace: true });
  }, [tp, chainId]);

  const { tokenVolumes } = useVolume();

  const filteredTokens = useMemo(() => {
    return tokens
      .map((t) => {
        const volume =
          tokenVolumes.find((r) => r.token_id === t.token_id)?.volume ?? 0;
        return {
          ...t,
          volume,
        };
      })
      .filter((t) => {
        if (tp === TokenProtocol.RUNES) {
          return t.token_id.startsWith("Bitcoin-runes");
        }
        if (tp === TokenProtocol.ICRC) {
          return (
            t.token_id === "sICP-native-ICP" ||
            t.token_id.startsWith("sICP-icrc-")
          );
        }
        if (tp === TokenProtocol.SPL) {
          return t.token_id.startsWith("Solana-");
        }
        if (tp === TokenProtocol.DOGE) {
          return t.token_id === "dogecoin-native-DOGE";
        }
      })
      .sort((a, b) => b.volume - a.volume);
  }, [tp, tokens.length]);

  const chainIds = useMemo(() => {
    return _.uniq(
      filteredTokens
        .flatMap((t) => t.token_ledger_id_on_chains)
        .map((t) => t.chain_id),
    );
  }, [filteredTokens.length, tp]);

  return (
    <VStack w="100%" alignItems="flex-start" mt={8} gap={0}>
      <Helmet>
        <title>Token List | Omnity Hub Explorer</title>
      </Helmet>
      <VStack w="100%" gap={1}>
        <Text fontSize={28}>Tokens List</Text>
        <HStack fontSize={14}>
          <Text color="gray.400">Not listed?</Text>
          <Link
            href="https://hub.omnity.network/runes/add%20runes"
            target="_blank"
            color="blue.400"
          >
            Add here
          </Link>
        </HStack>
      </VStack>

      <HStack w="100%" mt={4} mb={4} justifyContent="center">
        {Object.values(TokenProtocol).map((protocol) => {
          const isActive = protocol === tp;
          return (
            <Box
              key={protocol}
              bg={isActive ? "gray.600" : ""}
              px={6}
              cursor="pointer"
              onClick={() => {
                setTp(protocol);
              }}
              borderRadius="full"
            >
              <Text fontSize={24} fontWeight={600} color={isActive ? "" : ""}>
                {protocol}
              </Text>
            </Box>
          );
        })}
      </HStack>

      <HStack justifyContent="end" w="100%">
        <TicketFilterChainId
          chainId={chainId}
          setChainId={setChainId}
          chainIds={chainIds}
        />
      </HStack>

      <Divider bg="gray.500" w="100%" h={1} mt={4} />

      <Box w={{ base: window.innerWidth, md: "100%" }} overflowX="scroll">
        <TableContainer w={{ base: "100%", md: MD_WIDTH }}>
          <Table size="lg">
            <TableCaption>
              {tokens.length === 0 ? "There is no tokens" : ""}
            </TableCaption>
            <Thead>
              <Tr>
                <Th>Token</Th>
                <Th textAlign="right">TVL/Amount</Th>
                <Th textAlign="right">Chains</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredTokens
                .filter((t) => {
                  if (chainId) {
                    return t.token_ledger_id_on_chains
                      .map((t) => t.chain_id)
                      .includes(chainId);
                  }
                  return true;
                })
                .map((t) => {
                  const _volumeAmount = formatTokenAmount?.(
                    t.volumeAmount?.toString() ?? "0",
                    t.token_id,
                    false,
                  );
                  const assetType = getAssetProtocol({ token: t.token_id });

                  let value = Math.ceil(t.volume);
                  let amount = _volumeAmount?.balance ?? "0";
                  console.log("###1", amount, value);

                  if (chainId) {
                    amount = formatUnits(
                      BigInt(
                        t.amount_on_l2?.find((a) => a.chain_id === chainId)
                          ?.amount ?? 0,
                      ),
                      t.decimals,
                    );
                    value = Number(amount) * (Number(t.price) ?? 0);
                  }
                  return (
                    <Tr
                      key={t.token_id}
                      cursor="pointer"
                      _hover={{ bg: "gray.700" }}
                      onClick={() =>
                        navigate(`/token/${beautifyTokenId(t.token_id)}`)
                      }
                    >
                      <Td>
                        <HStack>
                          <Avatar
                            size="md"
                            src={t.icon}
                            name={t.symbol}
                            borderWidth={1}
                            borderColor="gray.500"
                          />
                          <VStack gap={0} alignItems="flex-start">
                            <Text fontWeight={600} fontSize={18}>
                              {t?.name}
                            </Text>
                            <HStack>
                              <AssetBadge assetType={assetType} />
                              <Badge colorScheme="blue" fontSize={12} size="sm">
                                {t.metadata.rune_id}
                              </Badge>
                            </HStack>
                          </VStack>
                        </HStack>
                      </Td>
                      <Td
                        fontSize={18}
                        fontWeight={600}
                        color="gray.200"
                        textAlign="right"
                        className="number"
                      >
                        <HStack justifyContent="flex-end">
                          <Text>${readableNumber(value, 0)}</Text>
                          <Text color="gray.400">
                            (
                            {readableNumber(
                              amount,
                              Number(t.price) > 1000
                                ? 4
                                : Number(t.price) > 100
                                  ? 2
                                  : 0,
                            )}
                            )
                          </Text>
                          {!chainId && <RunesOnL2Pie token={t} />}
                        </HStack>
                      </Td>
                      <Td textAlign="right">
                        <AvatarGroup size="sm" max={5} justifyContent="end">
                          {t.token_ledger_id_on_chains
                            .map((t) => t.chain_id)
                            .filter((chain_id) => {
                              return !!chains.find(
                                (c) => c.chain_id === chain_id,
                              );
                            })
                            .map((chain_id) => {
                              return (
                                <Avatar
                                  icon={
                                    <IChainLogo
                                      key={chain_id}
                                      chain={chain_id as ChainID}
                                      size={28}
                                    />
                                  }
                                  background="transparent"
                                  key={chain_id}
                                />
                              );
                            })}
                        </AvatarGroup>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </VStack>
  );
}
