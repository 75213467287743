import {
  chakra,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text,
  useDisclosure,
  VStack,
  useOutsideClick,
} from "@chakra-ui/react";
import { ChevronDown, XCircle } from "lucide-react";
import { useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { TicketStatus } from "src/types";
import { TICKET_STATUS_TITLES } from "src/utils/constants";

const ChevronDownIcon = chakra(ChevronDown);
const XCircleIcon = chakra(XCircle);

export default function TicketFilterStatus({
  status,
  setStatus,
}: {
  status?: TicketStatus;
  setStatus: (action: TicketStatus | undefined) => void;
}) {
  const ref = useRef(null);
  const { isOpen, onToggle, onClose } = useDisclosure();
  useOutsideClick({
    ref,
    handler: onClose,
  });
  const [_, setSearchParams] = useSearchParams();
  return (
    <>
      <Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={onClose}
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <HStack>
            <HStack
              borderWidth={0.5}
              borderColor="gray.500"
              _hover={{ bg: "gray.600" }}
              px={4}
              py={2}
              cursor="pointer"
              bg={status ? "gray.600" : "transparent"}
              onClick={onToggle}
            >
              <Text>{status ? TICKET_STATUS_TITLES[status] : "Status"}</Text>
              <ChevronDownIcon size={24} />
            </HStack>

            <XCircleIcon
              size={20}
              color={status ? "blue.500" : "transparent"}
              cursor="pointer"
              onClick={() => {
                status && setStatus(undefined);
                setSearchParams({ page: "1" }, { replace: true });
              }}
            />
          </HStack>
        </PopoverTrigger>
        <PopoverContent w={160} p={0} ref={ref}>
          <PopoverBody p={0}>
            <VStack alignItems="flex-start" w="100%" gap={0}>
              {Object.values(TicketStatus).map((_status) => {
                return (
                  <HStack
                    key={_status}
                    cursor="pointer"
                    w="100%"
                    pl={4}
                    py={2}
                    bg={status === _status ? "gray.600" : "transparent"}
                    _hover={{ bg: "gray.600" }}
                    onClick={() => {
                      setStatus(_status as TicketStatus);
                      setSearchParams({ page: "1" }, { replace: true });
                      onClose();
                    }}
                  >
                    <Text>{TICKET_STATUS_TITLES[_status]}</Text>
                  </HStack>
                );
              })}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
}
