import { TokenMeta } from "src/types";

export async function fetchTokenPrice(tokens: TokenMeta[]) {
  try {
    const prices = await fetch(
      `https://api.omnity.network/api/price/tokens`,
    ).then((res) => res.json());

    return tokens.map((t) => {
      const token_id = t.token_id;
      const price = prices[token_id] ?? "0";
      return {
        ...t,
        price,
      };
    });
  } catch (error) {
    return tokens;
  }
}
