import { Divider, Stack, Text, VStack } from "@chakra-ui/react";
import { gql } from "graphql-request";
import { fetchGraph } from "../utils/graphFetch";
import { useEffect, useMemo, useState } from "react";
import { TicketAction, TicketItem, TicketStatus } from "src/types";
import TicketTable from "src/components/TicketTable";
import { ChainID } from "@omnity/widget/src/types";
import { useSearchParams } from "react-router-dom";
import TicketFilterChainId from "src/components/TicketFilterChainId";
import TicketFilterTokenId from "src/components/TicketFilterTokenId";
import TicketFilterAction from "src/components/TicketFilterAction";
import TicketFilterStatus from "src/components/TicketFilterStatus";
import { Helmet } from "react-helmet";
import { PAGE_SIZE } from "src/utils/constants";
import Pagination from "src/components/Pagination";
import { useMetadata } from "src/contexts/metadata";
import { formatActionQuery } from "../utils/helper";
import { formatTickets } from "src/utils/format";

async function fetchLatestTickets(
  page = 1,
  chainId?: ChainID,
  action?: TicketAction,
  tokenId?: string,
  status?: TicketStatus,
): Promise<{ txs: TicketItem[]; count: number }> {
  const _tokenId = tokenId ? `token: { _eq: "${tokenId}" }` : "";
  const _chainId = chainId
    ? `_or: [
              { dst_chain: { _eq: "${chainId}" } }
              { src_chain: { _eq: "${chainId}" } }
            ]`
    : "";
  const _action = formatActionQuery(action);
  const _status = status ? `status: { _eq: "${status}" }` : "";
  try {
    const doc = gql`
      {
        ticket_aggregate(
          where: {
            ${_tokenId}
            ${_chainId}
            ${_action}
            ${_status}
          }
        ) {
          aggregate {
            count
          }
        }
        ticket(
          where: {
            ${_tokenId}
            ${_chainId}
            ${_action}
            ${_status}
          }
          order_by: { ticket_time: desc }
          limit: ${PAGE_SIZE}
          offset: ${(page - 1) * PAGE_SIZE}
        ) {
          action
          amount
          dst_chain
          memo
          receiver
          sender
          src_chain
          status
          ticket_id
          ticket_seq
          ticket_time
          ticket_type
          token
          tx_hash
        }
      }
    `;
    const data = await fetchGraph(doc, {});
    return {
      txs: formatTickets(data.ticket),
      count: data.ticket_aggregate.aggregate.count,
    };
  } catch (error) {
    return {
      txs: [],
      count: 0,
    };
  }
}

export default function Tickets() {
  const [searchParams, setSearchParams] = useSearchParams();

  const _page = searchParams.get("page") ?? "1";
  const _chainId = searchParams.get("chainId");
  const _action = searchParams.get("action");
  const _tokenId = searchParams.get("tokenId");
  const _status = searchParams.get("status");

  const [tickets, setTickets] = useState<TicketItem[]>([]);
  const [count, setCount] = useState<number>(0);
  const [chainId, setChainId] = useState<ChainID | undefined>(
    _chainId as ChainID,
  );
  const [action, setAction] = useState<TicketAction | undefined>(
    _action as TicketAction,
  );
  const [tokenId, setTokenId] = useState<string>(_tokenId ?? "");
  const [status, setStatus] = useState<TicketStatus | undefined>(
    _status as TicketStatus,
  );

  const page = Number(_page) ?? 1;
  useEffect(() => {
    fetchLatestTickets(page, chainId, action, tokenId, status).then((res) => {
      setTickets(res.txs);
      setCount(res.count);
    });
    const params: Record<string, string | string[]> = {};
    if (chainId) {
      params.chainId = chainId;
    }
    if (action) {
      params.action = action;
    }
    if (tokenId) {
      params.tokenId = tokenId;
    }
    if (status) {
      params.status = status;
    }
    if (page) {
      params.page = String(page);
    }
    setSearchParams(params, { replace: true });
  }, [chainId, action, tokenId, status, page]);

  const onClearAll = () => {
    setChainId(undefined);
    setAction(undefined);
    setTokenId("");
    setStatus(undefined);
  };

  const { tokens } = useMetadata();
  const validTokenIds = useMemo(() => {
    if (chainId) {
      return tokens
        .filter((t) =>
          t.token_ledger_id_on_chains.map((t) => t.chain_id).includes(chainId),
        )
        .map((t) => t.token_id);
    }
    return [];
  }, [chainId]);

  const validChainIds = useMemo(() => {
    if (tokenId) {
      return tokens.find((rune) => rune.token_id === tokenId)?.dst_chains ?? [];
    }
    return [];
  }, [tokenId]);

  return (
    <VStack pt={8} w="100%">
      <Helmet>
        <title>Ticket List | Omnity Hub Explorer</title>
      </Helmet>
      <Stack
        flexDir={{ base: "column", md: "row" }}
        w="100%"
        alignItems={{ base: "flex-start", md: "center" }}
        justifyContent="space-between"
        px={{ base: 4, md: 0 }}
      >
        <Text fontSize={28}>Ticket List</Text>
        <Stack
          gap={2}
          flexDir={{ base: "column", md: "row" }}
          color="gray.200"
          alignItems={{ base: "flex-start", md: "center" }}
          flexWrap={{ base: "wrap", md: "nowrap" }}
        >
          <TicketFilterChainId
            chainId={chainId}
            setChainId={setChainId}
            chainIds={validChainIds as ChainID[]}
          />
          <TicketFilterTokenId
            tokenId={tokenId}
            setTokenId={setTokenId}
            tokenIds={validTokenIds}
          />
          <TicketFilterAction action={action} setAction={setAction} />
          <TicketFilterStatus status={status} setStatus={setStatus} />
          <Text color="blue.500" cursor="pointer" onClick={onClearAll}>
            CLEAR ALL
          </Text>
        </Stack>
      </Stack>

      <Divider bg="gray.500" w="100%" h={1} mt={2} />

      <TicketTable items={tickets} />

      <Pagination total={count} page={page} />
    </VStack>
  );
}
