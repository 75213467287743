import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  chakra,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Link,
  HStack,
  Text,
  Avatar,
} from "@chakra-ui/react";
import ServiceFactory from "@omnity/widget/src/services/Factory";
import { TableIcon, ArrowUpRight } from "lucide-react";
import { MD_WIDTH } from "src/utils/constants";
import { beautifyAddress, beautifyTokenId } from "src/utils/format";
import IChainLogo from "./IChainLogo";
import { TokenMeta } from "src/types";
import { getChainName } from "src/utils/chains";
import { useMetadata } from "src/contexts/metadata";

const TableIconed = chakra(TableIcon);
const ArrowRightUpIcon = chakra(ArrowUpRight);

export default function TokenContractIdTable({ token }: { token?: TokenMeta }) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { chains } = useMetadata();
  if (!token) {
    return null;
  }
  return (
    <>
      <TableIconed
        size={26}
        color="blue.400"
        cursor="pointer"
        onClick={onOpen}
      />
      <Modal isCentered size="2xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="#000433">
          <ModalHeader>
            <HStack>
              <Avatar size="sm" src={token.icon} name={token.symbol} />
              <Text>
                <Text display="inline" color="blue.400">
                  {beautifyTokenId(token.token_id)}
                </Text>{" "}
                Contracts
              </Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer w={{ base: "100%", md: MD_WIDTH }}>
              <Table size="md">
                <Thead>
                  <Tr>
                    <Th>Chain</Th>
                    <Th>Contract Address</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {token.token_ledger_id_on_chains
                    .filter((t) => {
                      return !!chains.find((c) => c.chain_id === t.chain_id);
                    })
                    .map((item) => {
                      const url = ServiceFactory.getTokenLink({
                        chain_id: item.chain_id,
                        id: beautifyAddress(item.contract_id),
                        symbol: "",
                      });
                      return (
                        <Tr
                          key={item.chain_id}
                          borderBottomWidth={1}
                          borderBottomColor="yellow"
                        >
                          <Td>
                            <HStack>
                              <IChainLogo chain={item.chain_id} size={24} />
                              <Text fontWeight={600}>
                                {getChainName(item.chain_id)}
                              </Text>
                            </HStack>
                          </Td>
                          <Td>
                            <Link color="blue.400" href={url} target="_blank">
                              <HStack>
                                <Text fontFamily="monospace" fontSize={20}>
                                  {beautifyAddress(item.contract_id)}
                                </Text>
                                <ArrowRightUpIcon size={20} color="blue.400" />
                              </HStack>
                            </Link>
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
