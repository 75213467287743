/* eslint-disable @typescript-eslint/no-explicit-any */
import { request } from "graphql-request";

const HOST = "https://hub-hasura-mainnet.omnity.network/v1/graphql";

export const fetchGraph = async (query: string, variables: any) => {
  try {
    const data = await request({
      url: HOST,
      document: query,
      variables,
    });
    return data as any;
  } catch (error) {
    console.error(error);
    return null;
  }
};
